import { ILoadPaginatedForOrg, serializePaginatedQueryValues } from "@smartrr/shared/utils/paginatedQuery";

import { SmartrrThunkAction } from "@vendor-app/app/_state/typedVendorRedux";
import { vendorAsyncDispatch } from "@vendor-app/app/_state/vendorAsyncDispatch";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export function getOrders({ queryParams }: ILoadPaginatedForOrg) {
  return typedFrontendVendorApi.getReq("/order", {
    query: serializePaginatedQueryValues(queryParams),
  });
}

export function getSubscriptionOrdersByIdOrShopifyIdAPI(shopifySubscriptionId: string, params?: any) {
  return typedFrontendVendorApi.getReq("/purchase-state/:shopifySubscriptionId/orders", {
    params: {
      shopifySubscriptionId,
    },
    query: params ? serializePaginatedQueryValues(params) : undefined,
  });
}

export const loadOrders =
  ({
    queryParams,
  }: ILoadPaginatedForOrg): // paginatedQuery: ILoadPaginatedForOrg
  SmartrrThunkAction<"LOADING_ORDERS" | "ERROR_LOADING_ORDERS" | "LOADED_ORDERS"> =>
  dispatch =>
    vendorAsyncDispatch(
      getOrders({ queryParams }),
      () =>
        dispatch({
          type: "LOADING_ORDERS",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_LOADING_ORDERS",
          payload: {
            errorMessage: failure.message,
          },
        }),
      paginatedOrders =>
        dispatch({
          type: "LOADED_ORDERS",
          payload: {
            paginatedOrders,
          },
        })
    );

export const deleteOrder =
  ({
    orderId,
  }: {
    orderId: string;
  }): SmartrrThunkAction<"DELETING_ORDER" | "ERROR_DELETING_ORDER" | "DELETED_ORDER"> =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.deleteReq("/order/:orderId", {
        params: {
          orderId,
        },
      }),
      () =>
        dispatch({
          type: "DELETING_ORDER",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_DELETING_ORDER",
          payload: {
            errorMessage: failure.message,
          },
        }),
      () =>
        dispatch({
          type: "DELETED_ORDER",
          payload: {
            orderId,
          },
        })
    );

export const openOrder =
  ({
    orderId,
  }: {
    orderId: string;
  }): SmartrrThunkAction<"OPENING_ORDER" | "ERROR_OPENING_ORDER" | "OPENED_ORDER"> =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.postReq("/order/:orderId/open", {
        params: {
          orderId,
        },
      }),
      () =>
        dispatch({
          type: "OPENING_ORDER",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_OPENING_ORDER",
          payload: {
            errorMessage: failure.message,
          },
        }),
      () =>
        dispatch({
          type: "OPENED_ORDER",
          payload: {
            orderId,
          },
        })
    );

export const getSubscriptionOrdersByIdOrShopifyId =
  ({
    subscriptionId,
    queryParams,
  }: ILoadPaginatedForOrg & {
    subscriptionId: string;
  }): SmartrrThunkAction<
    "LOAD_PURCHASE_STATE_ORDERS" | "ERROR_LOADING_PURCHASE_STATE_ORDERS" | "LOADED_PURCHASE_STATE_ORDERS"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      getSubscriptionOrdersByIdOrShopifyIdAPI(subscriptionId, queryParams),
      () =>
        dispatch({
          type: "LOAD_PURCHASE_STATE_ORDERS",
          payload: undefined,
        }),
      failure => {
        return dispatch({
          type: "ERROR_LOADING_PURCHASE_STATE_ORDERS",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      paginatedOrders => {
        return dispatch({
          type: "LOADED_PURCHASE_STATE_ORDERS",
          payload: {
            paginatedOrders,
          },
        });
      }
    );
