import { ISODateString } from "@smartrr/shared/entities/ISODateString";

export const getOrderDate = (orderProcessedDate: ISODateString, billingTimezone?: string) => {
  if (!orderProcessedDate || !billingTimezone) {
    return "——";
  }
  const date = ISODateString.fromString(orderProcessedDate).setZone(billingTimezone);

  return `${date.toLocaleString({
    day: "2-digit",
    month: "short",
    year: "numeric",
  })} at ${date.toLocaleString({
    hour: "numeric",
    hour12: true,
  })}`;
};
